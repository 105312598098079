import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"

          href="https://faelixyz.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Guns.lol Link
        </a>
      </header>
    </div>
  );
}

export default App;
